import React from "react"
// import { Redirect } from "@reach/router"
import { SectionTitle } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor } from "../../../site-config"
import StyledMain from "../../components/styled-main"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const StyledLi = styled.li`
  margin-bottom: 1rem;
`

export default () => {
  // return <Redirect to="services/ingrown-toenail-treatment" noThrow />
  return (
    <Layout>
      <SEO
        title="Services at Prendiville Ave Podiatry"
        href="https://mopodiatry.com.au/services"
      />
      <SectionTitle
        text="Services at Prendiville Ave Podiatry"
        color={primaryColor}
        level="h1"
        size="2rem"
      />
      <StyledMain>
        <ul>
          <StyledLi>Ingrown toenails fixed</StyledLi>
          <StyledLi>Hard skin (corns, calluses etc.) removed</StyledLi>
          <StyledLi>Skin problems solved</StyledLi>
          <StyledLi>Toenail troubles treated</StyledLi>
          <StyledLi>Diabetic management</StyledLi>
          <StyledLi>Heel pain therapy</StyledLi>
          <StyledLi>Orthotics</StyledLi>
        </ul>
      </StyledMain>
    </Layout>
  )
}
